@import "./custom";

body,
html {
  font-family: "Montserrat", sans-serif;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: $txt-primary;
}

.bg-home {
  background: url("../images/bg-header.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}

.bg-why {
  background: url("../images/bg-why.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}

.bg-blue {
  background-color: $primary;
}

.bg-alabaster {
  background-color: $pale-grey;
}

p,
.p {
  font-size: 1rem;
}

h1,
.h1 {
  font-size: 48px;
}

h2,
.h2 {
  font-size: 32px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 11px;
}

.fwn {
  font-weight: 400;
}

.fwm {
  font-weight: 500;
}

.bg-cover {
  width: 100%;
  height: 344px;
  background-position: center;
  background-size: cover;
}

.bg-stone {
  background-color: $stone;
}

.bg-grey {
  background-color: $white-2;
}

.icon-md {
  height: 50px;
  margin-left: 10px;
}

.card {
  border-radius: 8px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  border: none;
}

.tred {
  color: $red !important;
}

.tblack {
  color: $black !important;
}

.tblue {
  color: $primary !important;
}

.torange {
  color: $orange !important;
}

.tgreen {
  color: $green !important;
}

.border-0 {
  border: none;
  box-shadow: none;
}

div.highlight-res {
  position: absolute;
  width: 97%;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 16%);
  border: none;
  border-radius: 0;
  top: -4rem;
}

ul.highlight {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

ul.highlight li {
  display: inline-block;
}

ul.highlight li img {
  height: 45px;
  margin: 0 10px;
}

div.highlight {
  position: absolute;
  width: 97%;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 16%);
  border: none;
  border-radius: 0;
  top: -4rem;
}

.card_blog {
  border: none;
  background: $pale-grey;
  border-radius: 0;
  box-shadow: none;
}

.card.grblue {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background: rgb(0, 42, 74);
  background: linear-gradient(
    90deg,
    rgba(0, 42, 74, 1) 0%,
    rgba(0, 126, 220, 1) 55%
  );
  border-radius: 10px;
  border: none;
}

img.hero-cta {
  position: absolute;
  width: 380px;
  bottom: -56px;
}

.mt-10 {
  margin-top: 5.5rem;
}
.list-cta {
  list-style: none;
  padding-left: 0;
  margin-top: 30px;
}

.list-cta li {
  display: inline-block;
}

.img-cta {
  width: 200px;
}

.img-sosmed {
  height: 30px;
  margin: 0 30px;
}

footer ol {
  padding-left: 10px;
}

footer ol li {
  font-size: 14px;
  padding-left: 15px;
  font-weight: 400;
  line-height: 28px;
}

footer ul {
  list-style: none;
  padding-left: 0;
  line-height: 35px;
}

footer ul li a,
footer a {
  color: $silver;
  font-weight: 400;
  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

.underline-0 {
  text-decoration: none !important;
}

.card-link {
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
}

.card-link a {
  text-decoration: none;
}

.card-link img {
  border-radius: 8px 8px 0 0;
}

.copyright {
  font-size: 12px;
  color: $silver;
}

.btn {
  border-radius: 5px;
  line-height: 1.29;
  font-weight: 600;
}

.btn-green {
  border: none !important;
  background-color: $green !important;
  &:hover,
  &:focus,
  &:active {
    background-color: $green-hover !important;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  }
}

.btn-cart {
  border: 1px solid #ffaa00 !important;
  background-image: linear-gradient(to bottom, #ffaa00, #ff8a3b);
  &:hover,
  &:focus,
  &:active {
    background-color: $orange-hover !important;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  }
}
.btn.btn-cart-o {
  background: $white !important;
  color: $orange !important;
  border: 1px solid $orange !important;
  &:hover,
  &:focus,
  &:active {
    background-color: $orange !important;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
    color: $white !important;
  }
  &:disabled {
    background: $white !important;
    color: $orange !important;
  }
}

.btn-yellow {
  background-color: $yellow;
  color: $stone !important;
  border: none;
  font-weight: 700;
  &:hover,
  &:focus,
  &:active {
    background-color: #d3a21d !important;
  }
}

a.nav-item.nav-link {
  font-weight: 500;
  color: $greyish;
  font-size: 18px;
}

span.cal-value {
  float: right;
  font-size: 22px;
  font-weight: 700;
  color: $primary;
  line-height: 0.77;
  letter-spacing: -0.3px;
}

.pointer,
a {
  cursor: pointer !important;
}

.highlight-footer {
  background: #def1ff;
  padding: 15px;
  text-align: center;
  color: #142535;
  font-weight: 500;
  font-size: 13px;
}

.bg-light {
  background-color: $white !important;
  box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 16%);
}

hr.line-faq {
  border-top: 1px solid rgb(117 117 117 / 10%);
  margin: 3rem 0;
}

.logo-md {
  height: 20px;
  width: auto;
  margin: auto;
}

ul.logo-box {
  list-style: none;
  padding-left: 0;
  margin-top: 20px;
}

ul.logo-box li {
  display: inline-block;
}
ul.logo-box li img {
  height: 80px;
}

.br-1 {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.bb-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .bb-responsive {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

label.form-label {
  font-weight: 500;
  font-size: 15px;
  color: $stone;
}

.form-control,
.rselect-prefix__control {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  font-size: 14px;
  color: $stone;
}

.form-control::placeholder {
  color: $silver;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: $silver;
}

.form-control::-ms-input-placeholder {
  color: $silver;
}

.ch-regis,
.modal-header {
  background-color: $primary;
  color: $white;
  font-size: 1.4em;
  font-weight: 600;
  border: 0;
  border-radius: 8px 8px 0 0 !important;
}

.filepond--drop-label label {
  font-size: 13px;
}

span.badge-top {
  background-color: tomato;
  color: #fff;
  font-size: 8px;
  padding: 3px 4px;
  margin-left: 5px;
}

.shadow-0 {
  box-shadow: none;
}
span.card-loan {
  font-weight: 600;
  font-size: 14px;
}

.card-loan p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.card-loan h6 {
  color: $steel;
  font-weight: 500;
}

.frisk {
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  color: $greyish;
}

.modal-content {
  border-radius: 10px;
}

.nav-dash {
  font-size: 16px;
  font-weight: 400;
  color: $silver;
  letter-spacing: 0.4px;
  text-decoration: none !important;
  &.active {
    color: $primary;
    font-weight: 600;
    & .tnav-dash {
      display: inline-block;
      margin-left: 4px;
    }
  }
  &:hover {
    color: $primary;
  }
  & .tnav-dash {
    display: none;
  }
}

.copied-alert-va {
  position: absolute;
  border-radius: 5px;
  background-color: $primary;
  color: $white;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 10px;
  z-index: 99 !important;
  right: 0;
}

.circle-top {
  position: absolute;
  top: 0;
  margin-left: 5px;
  font-size: 10px;
  color: $green;
}

.slick-prev,
.slick-next {
  color: $silver !important;
  height: 50px !important;
  width: 50px !important;
  font-size: 3rem !important;
}

.slick-next {
  right: -75px !important;
}

.slick-prev {
  left: -75px !important;
}

.slick-prev:before,
.slick-next:before {
  content: none !important;
}

ul.slick-dots {
  bottom: -3rem;
}
.agreement-overflow {
  height: 500px;
  overflow-y: auto;
}

.account-field {
  margin-bottom: 30px;
}

.card-header {
  background-color: $primary;
  color: $white;
  font-size: 1.4em;
  font-weight: 600;
  border: 0;
  border-radius: 8px 8px 0 0 !important;
}
.icon-img {
  width: 120px;
}

.filter-sticky {
  background: white;
  position: fixed;
  bottom: 3rem;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 5;
  padding: 8px 15px;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #dbdbdb;
  cursor: pointer;
  border-radius: 28px;
}

.cart-floating {
  background: white;
  position: fixed;
  bottom: 0;
  z-index: 9;
  border-top: 1px solid #dbdbdb;
  width: 100%;
}

.plat-title {
  margin: -35px 10px 0;
  padding: 10px 0 5px;
  text-align: center;
  background-color: $white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
}

.txt-glow {
  color: #ffd705;
  text-align: center;
  text-shadow: 0px 0px 3.5px #ffd705;
  font-size: 30px;
  font-weight: 700;
  background-color: #02322b;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  margin-top: -20px;
}

ul.lender-logo {
  list-style: none;
  padding: 20px 0 0 0;
  margin-bottom: 0;
  text-align: center;
  & li {
    display: inline;
  }
  & img {
    max-height: 80px;
    max-width: 1800px;
    padding: 15px 30px;
  }
}
