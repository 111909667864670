// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .bg-home,
  .bg-why {
    background-position: center;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .bg-home,
  .bg-why {
    background-position: center;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .txt-glow {
    margin-top: -35px;
  }

  tbody.tbl-mutasi {
    font-size: 14px;
  }
  .nav-dash.active .tnav-dash {
    display: none;
  }
  .card-loan {
    margin: 10px 0;
  }
  .card-loan h6 {
    margin-bottom: 3px;
  }
  h1,
  .h1 {
    font-size: 32px;
  }

  h2,
  .h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  h6,
  .h6 {
    font-size: 12px;
  }
}

// For 4" phone
@media (max-width: 320px) {
}
