$blue: #007edc;
$cyan: #007b9a;
$green-hover: #0b6533;
$green: #0e8642;
$greyish: #4e4e4e;
$orange-hover: #ef430c;
$orange: #ff5722;
$pale-grey: #f5f6f8;
$primary: #056070;
$red: #ca2222;
$secondary: #ffe147;
$silver: #b1b1b1;
$steel: #535f65;
$stone: #142535;
$txt-primary: #021f24;
$white-2: #f8f8f8;
$yellow: #ffba00;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #191919;
$black: #000;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);

$primary: $primary;
$secondary: $secondary;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

/* make the customizations */
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
